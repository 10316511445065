import axios from 'axios';

const WoosmapClient = (publicKey, locale) => {
  const MODES = {
    WALKING: 'walking',
    DRIVING: 'driving',
    CYCLING: 'cycling'
  };

  const OK_STATUS = 'OK';

  const client = axios.create({
    baseURL: 'https://api.woosmap.com',
    params: {
      key: publicKey
    }
  });

  const getLocation = address => {
    return client
      .get('/address/geocode/json', { params: { address } })
      .then(response => {
        return response.data.results[0];
      });
  };

  function formatCoordinates({ lat, lng }) {
    return `${lat},${lng}`;
  }

  const getDistanceMatrix = (origin, destination, mode) => {
    return client
      .get('/distance/distancematrix/json', {
        params: {
          origins: formatCoordinates(origin),
          destinations: formatCoordinates(destination),
          mode,
          elements: 'duration_distance',
          language: locale
        }
      })
      .then(response => {
        if (response.data.status !== OK_STATUS) {
          return null;
        }
        const element = response.data.rows[0].elements[0];

        return element.status !== OK_STATUS ? null : element;
      });
  };

  return { getLocation, getDistanceMatrix, MODES };
};

export default WoosmapClient;
