const isServer = () => typeof window === 'undefined';

export function coordsInGivenBounds(coords, boundsNeSw) {
  if (isServer()) {
    return false;
  }
  const bounds = new window.google.maps.LatLngBounds(
    boundsNeSw.sw,
    boundsNeSw.ne
  );
  const latlng = new window.google.maps.LatLng(coords.lat, coords.lng);

  let inBounds = false;
  if (bounds && latlng && bounds.contains(latlng)) {
    inBounds = true;
  }

  return inBounds;
}

export function getMapTypeOptions() {
  if (isServer()) {
    return false;
  }
  return {
    mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain'],
    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    position: window.google.maps.ControlPosition.LEFT_BOTTOM
  };
}

export function getZoomOptions() {
  if (isServer()) {
    return false;
  }
  return {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM
  };
}
