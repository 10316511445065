import axios from 'axios';

const DEFAULT_DOMAIN = '';
const API_PREFIX = 'api/fe/marketplace';

export function getPoiRequest(city, options = {}) {
  const headers = {};
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const path = `${domain}/${API_PREFIX}/poi/${city}`;

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.get(path, { headers });
}

export function setPoiRequest(city, place, options = {}) {
  const headers = {};
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const path = `${domain}/${API_PREFIX}/poi/${city}`;

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.post(path, { place }, { headers });
}

// TODO: check
export function deletePoiRequest(city, options = {}) {
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const path = `${domain}/${API_PREFIX}/poi/${city}`;
  const headers = {};

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.delete(path, { headers });
}

export default {
  getPoiRequest,
  setPoiRequest,
  deletePoiRequest
};
