import {
  CYCLING_TRANSPORT,
  DRIVING_TRANSPORT,
  TRANSIT_TRANSPORT,
  WALKING_TRANSPORT
} from '../constants';

const parseCoordinates = ({ lat, lng }) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng)
});

const requestDistance = service => async (origin, destination, travelMode) => {
  const originCoordinates = parseCoordinates(origin);
  const destinationCoordinates = parseCoordinates(destination);

  const element = await service.getDistanceMatrix(
    originCoordinates,
    destinationCoordinates,
    travelMode
  );

  return element === null
    ? null
    : {
        distance: element.distance,
        duration: element.duration,
        travelMode
      };
};
class DistanceAPI {
  constructor(distanceClient) {
    this.requestDistance = requestDistance(distanceClient);
    this.TRAVEL_MODE = {
      [CYCLING_TRANSPORT]: distanceClient.MODES.CYCLING,
      [DRIVING_TRANSPORT]: distanceClient.MODES.DRIVING,
      [WALKING_TRANSPORT]: distanceClient.MODES.WALKING
    };

    this.TRAVEL_MODE_KEYS = {
      cycling: CYCLING_TRANSPORT,
      driving: DRIVING_TRANSPORT,
      walking: WALKING_TRANSPORT
    };
  }

  getTransportMean(travelMode) {
    return this.TRAVEL_MODE_KEYS[travelMode] || '';
  }

  getDistance = (originOpts, destinationOpts, travelMode) =>
    this.requestDistance(originOpts, destinationOpts, travelMode);

  getWalkingDistance = (originOpts, destinationOpts) =>
    this.getDistance(
      originOpts,
      destinationOpts,
      this.TRAVEL_MODE[WALKING_TRANSPORT]
    );

  getTransitDistance = (originOpts, destinationOpts) =>
    this.getDistance(
      originOpts,
      destinationOpts,
      this.TRAVEL_MODE[TRANSIT_TRANSPORT]
    );

  getAllDistances(originOpts, destinationOpts) {
    const promiseArray = Object.keys(this.TRAVEL_MODE_KEYS).map(travelMode =>
      this.getDistance(originOpts, destinationOpts, travelMode)
    );

    const cleanNullValues = array => array.filter(Boolean);

    return Promise.all(promiseArray).then(results => cleanNullValues(results));
  }
}

export default DistanceAPI;
