import { useState } from 'react';

import { useGoogleMapsScript } from '@spotahome/marketplace-common/src/utils/google-maps-script';

import InteractiveMap from './InteractiveMap';

const InteractiveMapContainer = props => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useGoogleMapsScript(() => setIsMapLoaded(true));

  return isMapLoaded ? <InteractiveMap {...props} /> : null;
};

export default InteractiveMapContainer;
